// Dev styles
.devred { border: 1px solid red !important; }
.devblue { border: 1px solid blue !important; }
.devmag {  border: 1px solid magenta !important; }


// VA! Disable annoying sass-lint rules - force-element-nesting is now disabled in sass-lint.yml
// sass-lint:disable force-element-nesting

html {
  font-size: 16px;
}

// VA! Additional styles not in Codepen demo
body { 
  // VA! Sherwin williams nonchalant white SW 6161
  background: #a7a7a7;
  font-family: 'Lato', Helvetica, sans-serif; 
  box-sizing: border-box;
}


.page__wrap {
  display: flex; 
  justify-content: center;
  width: 100%;

}

.main__content {
  display: block;
  margin: 0 auto;
  @include small  {
    width: 320px;
  }
  @include notsmall  {
    width: 700px;
  }
  @include desktop  {
    width: 1000px;
  }
  img {
    display: block;
    margin: 0 auto;
    @include small  {
      width: 290px;
      border: 5px solid #FFFFFF;
    }
    @include notsmall  {
      width: 100%;
      margin-left: -10px;
      border: 10px solid #FFFFFF;
    }
  }
}

.main__content-header {
  color: #FFFFFF;
  font-size: 2rem;
  margin: 0;
  padding: 40px 0 10px;
  @include small  {
    color: #FFFFFF;
    font-size: 1.5rem;
    margin: 0;
    padding: 80px 0 3px;
    text-align: center;
  }
  @include notsmall  {
    color: #FFFFFF;
    font-size: 2rem;
    margin: 0;
    padding: 40px 0 10px;
    text-align: left;
  }
}

.main__content-subtext {
  color: #FFFFFF;
  @include small  {
    font-size: 1rem;
    text-align: center;
  }
  @include notsmall  {
    font-size: 1.2rem;
    text-align: right;
  }
  @include desktop  {
    font-size: 1.5rem;
  }
}




// VA! flex-container definitions

