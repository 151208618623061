// VA! Variables and Mixins

// VA! Media queries

$hires: 1281px;
$desktop: 1025px;
$tablet: 768px;
$lphone: 481px;
$sphone: 320px; 

// VA! MQ values from https://gist.github.com/gokulkrishh/242e68d1ee94ad05f488



@mixin small {
  @media only screen and (min-device-width: 320px) and (max-device-width: 480px) and (-webkit-min-device-pixel-ratio: 2) {
    @content;
  }
}


// VA! This is the mixin for everything above small phone. We're doing mobile-first, so we will code for progressively larger devices.
@mixin notsmall {
  @media screen and (min-device-width: 481px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    @content;
  }
}

@mixin desktop {
  @media screen and (min-device-width: 801px) 
  and (-webkit-min-device-pixel-ratio: 1) { 
    @content;
  }
}
